var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  merge: _vm.grid.merge,
                  columns: _vm.grid.columns,
                  data: _vm.grid.data,
                  editable: !_vm.disabled,
                  gridHeightAuto: true,
                  columnSetting: false,
                  filtering: false,
                  usePaging: false,
                },
                on: { headerDataChange: _vm.headerDataChange },
                scopedSlots: _vm._u([
                  {
                    key: "customArea",
                    fn: function ({ props, col }) {
                      return [
                        col.name === "customCol"
                          ? [
                              _c(_vm.imprComponent, {
                                tag: "component",
                                attrs: {
                                  col: col,
                                  props: props,
                                  inputEditable: _vm.editable && !_vm.disabled,
                                  isImmShow: true,
                                  requestContentsCols: _vm.requestContentsCols,
                                  tableKeys: _vm.tableKeys,
                                  ibmTaskTypeCd: "ITT0000070",
                                  ibmTaskUnderTypeCd: "ITTU000123",
                                },
                                on: { imprChange: _vm.imprChange },
                              }),
                            ]
                          : col.name === "remark"
                          ? [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable,
                                  props: props,
                                  col: col,
                                  disabled: _vm.disabled,
                                },
                                on: {
                                  dataChange: function ($event) {
                                    return _vm.datachange1(props)
                                  },
                                },
                                model: {
                                  value: props.row["remark"],
                                  callback: function ($$v) {
                                    _vm.$set(props.row, "remark", $$v)
                                  },
                                  expression: "props.row['remark']",
                                },
                              }),
                            ]
                          : [
                              _c("c-select", {
                                attrs: {
                                  stype: "tableselect",
                                  editable: _vm.editable,
                                  comboItems: _vm.checkboxItems,
                                  itemText: "codeName",
                                  itemValue: "code",
                                  type: "edit",
                                  label: "",
                                  name: "checkItems",
                                },
                                on: {
                                  datachange: function ($event) {
                                    return _vm.datachange2(props)
                                  },
                                },
                                model: {
                                  value: props.row[col.name],
                                  callback: function ($$v) {
                                    _vm.$set(props.row, col.name, $$v)
                                  },
                                  expression: "props.row[col.name]",
                                },
                              }),
                            ],
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "suffixTitle" },
                  [
                    _c(
                      "font",
                      {
                        staticClass: "text-negative",
                        staticStyle: {
                          "font-size": "0.8em",
                          "font-weight": "300",
                        },
                        attrs: { color: "red" },
                      },
                      [
                        _c("q-icon", {
                          staticClass: "text-black",
                          attrs: {
                            name: "radio_button_unchecked",
                            color: "red",
                          },
                        }),
                        _vm._v("정상 "),
                        _c("q-icon", {
                          staticClass: "text-black",
                          attrs: { name: "close", color: "red" },
                        }),
                        _vm._v("이상 "),
                        _c("q-icon", {
                          staticClass: "text-black",
                          attrs: { name: "circle", color: "red" },
                        }),
                        _vm._v("청소 V교환 "),
                        _c("q-icon", {
                          staticClass: "text-black",
                          attrs: {
                            name: "check_box_outline_blank",
                            color: "red",
                          },
                        }),
                        _vm._v("마모 △누유 ▲필요 "),
                        _c("q-icon", {
                          staticClass: "text-black",
                          attrs: { name: "radio_button_checked", color: "red" },
                        }),
                        _vm._v("비가동 "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "template",
                  { slot: "table-header-append" },
                  [
                    _vm.editable && !_vm.disabled
                      ? _c("c-select", {
                          attrs: {
                            comboItems: _vm.dateItems,
                            type: "custom",
                            typetxt: "일자선택",
                            itemText: "dayName",
                            itemValue: "dayCode",
                            name: "today",
                            label: "",
                          },
                          on: { datachange: _vm.datechange },
                          model: {
                            value: _vm.today,
                            callback: function ($$v) {
                              _vm.today = $$v
                            },
                            expression: "today",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      [
                        _vm.editable &&
                        !_vm.disabled &&
                        !_vm.data.sysApprovalRequestId
                          ? _c("c-btn", {
                              attrs: {
                                url: _vm.saveUrl,
                                isSubmit: _vm.isSave,
                                param: _vm.data.dailyCheckId
                                  ? this.grid.data
                                  : this.data,
                                mappingType: "POST",
                                label: "저장",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveData,
                                btnCallback: _vm.saveCallback,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }