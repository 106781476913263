<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          :merge="grid.merge"
          :columns="grid.columns"
          :data="grid.data"
          :editable="!disabled"
          :gridHeightAuto="true"
          :columnSetting="false"
          :filtering="false"
          :usePaging="false"
          @headerDataChange="headerDataChange"
        >
          <template slot="suffixTitle">
            <font class="text-negative" color="red"  style="font-size:0.8em;font-weight:300;">
              <q-icon name="radio_button_unchecked" color="red"  class="text-black"/>정상
              <q-icon name="close" color="red" class="text-black"/>이상
              <q-icon name="circle" color="red" class="text-black"/>청소
              V교환
              <q-icon name="check_box_outline_blank"  color="red" class="text-black"/>마모
              △누유
              ▲필요
              <!-- <q-icon name="warning_amber" class="text-black"/> : Oil 누유 &nbsp;
              <q-icon name="warning" class="text-black"/> : 점검필요 &nbsp; -->
              <q-icon name="radio_button_checked" color="red"  class="text-black"/>비가동
            </font>
          </template>
          <template slot="table-header-append">
            <c-select
              v-if="editable && !disabled"
              :comboItems="dateItems"
              type="custom"
              typetxt="일자선택"
              itemText="dayName"
              itemValue="dayCode"
              name="today"
              label=""
              v-model="today"
              @datachange="datechange"
            ></c-select>
          </template>
          <template slot="table-button">
            <q-btn-group>
              <c-btn
                v-if="editable && !disabled && !data.sysApprovalRequestId"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="data.dailyCheckId ? this.grid.data : this.data"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='customCol'">
              <!-- tableKey=" " -->
              <component
                :is="imprComponent"
                :col="col"
                :props="props"
                :inputEditable="editable&&!disabled"
                :isImmShow="true"
                :requestContentsCols="requestContentsCols"
                :tableKeys="tableKeys"
                ibmTaskTypeCd="ITT0000070"
                ibmTaskUnderTypeCd="ITTU000123"
                @imprChange="imprChange"
              >
              </component>
            </template>
            <template v-else-if="col.name === 'remark'">
              <c-text
                :editable="editable"
                :props="props"
                :col="col"
                :disabled="disabled"
                v-model="props.row['remark']"
                @dataChange="datachange1(props)"
              />
            </template>
            <template v-else>
              <c-select
                stype="tableselect"
                :editable="editable"
                :comboItems="checkboxItems"
                itemText="codeName"
                itemValue="code"
                type="edit"
                label=""
                name="checkItems"
                v-model="props.row[col.name]"
                @datachange="datachange2(props)"
              >
              </c-select>
            </template>
          </template>
        </c-table>
      </div>  
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
import mobileConfig from '@/js/mobileConfig';
export default {
  name: 'equipment-daily-result',
  props: {
    data: {
      type: Object,
      default: () => ({
        dailyCheckId: '',  // 열화점검 일련 번호
        plantCd: '',  // 사업장 코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        lineId: '',  // 라인일련번호
        checkDeptCd: '',  // 점검부서
        checkUserId: '',  // 점검자 ID
        checkDate: '',  // 점검월
        dailyName: '',  // 점검명
        evaluationCompleteFlag: 'N',  // 결재완료 후 평가완료 처리
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        utilityFlag: 'N',
        dailyResultModelList: [], // sheet
        dailyImproveModelList: [], 
      }),
    },
    dateItems: {
      type: Array,
      default: () => [],
    },
    grid: {
      type: Object,
      default: () => ({
        height: 'auto',
        columns: [],
        data: [],
      }),
    },
  },
  data() {
    return {
      year: '',
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      updateMode: false,
      editable: true,
      saveUrl: transactionConfig.sop.min.equipment.plan.daily.insert.url,
      insertUrl: '',
      updateUrl: '',
      isSave: false,
      isComplete: false,
      isApproval: false,
      mappingType: 'POST',
      currentMonth: '',
      lunarHolidays: [],
      today: '',
      columnKey: '',
      checkboxItems: [
        // { code: null, codeName: ''},
        { code: 'CHK001', codeName: 'o'},
        { code: 'CHK002', codeName: 'X'},
        { code: 'CHK003', codeName: '●'},
        { code: 'CHK004', codeName: 'V'},
        { code: 'CHK005', codeName: '□'},
        { code: 'CHK006', codeName: '△'},
        { code: 'CHK007', codeName: '▲'},
        { code: 'CHK008', codeName: '⊙'},
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
     return this.data.checkStatusCd === 'MCSC000015' || Boolean(this.data.sysApprovalRequestId)
    },
    // 테이블 키 멀티
    tableKeys() {
      return ['dailyCheckResultId','colkey'];
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['checkItemPartName', 'checkItemName'];
    },
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'./inpsectionTableImpr.vue'}`);
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      
      this.currentMonth= this.$comm.getToday().slice(-5, this.$comm.getToday().length -3);
      if (this.data.checkDate.substring(5) === this.currentMonth) {
        this.today= this.$comm.getToday().slice(-2, this.$comm.getToday().length);
      } else {
        this.today = '01'
      }
      if (String(this.today.charAt(0)) === '0') {
        this.today = this.today.substring(1)
      }
      this.columnKey = 'day' + this.today; 
      // url setting
      this.getUrl = mobileConfig.sop.min.equipment.plan.daily.get.mobile.url;
      this.insertUrl = transactionConfig.sop.min.equipment.plan.daily.insert.url;
      this.updateUrl = transactionConfig.sop.min.equipment.result.daily.newItem.url;
      // code setting
      // list setting
    },
   
    saveData() {
      if (this.data.dailyCheckId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.regUserId = this.$store.getters.user.userId;
          this.data.chgUserId = this.$store.getters.user.userId;

          this.$_.forEach(this.grid.data, _item => {
            _item.regUserId = this.$store.getters.user.userId;
            _item.chgUserId = this.$store.getters.user.userId;
            if (!_item['day'+this.today]) {
              this.$set(_item, 'day'+this.today, _item.remark)
            }
          })

          if (this.mappingType === 'POST') {
            this.$_.forEach(this.grid.data, _item => {
              this.$_.forEach(this.grid.data, __item => {
                if(__item.dailyCheckResultId === _item.dailyCheckResultId) {
                  __item = _item;
                }
              })
            })
          } else if (this.mappingType === 'PUT') {
            this.grid.data = this.$_.clone(this.grid.data);
          } 

          this.data.utilityFlag = 'N';
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType === 'POST') {
        this.data.dailyCheckId = result.data
        this.returnData.col1 = result.data
        this.$_.forEach(this.grid.data, _item => {
          _item.dailyCheckId = result.data;
          _item.editFlag = 'U';
        })
      }
    },
    datachange2(props) {
      props.row['remark'] = null;
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    datachange1(props) {
      props.row['day' + this.today] = null;
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    headerDataChange(props, col) {
      if (col.name === String('day' + this.today)) {
        this.$_.forEach(this.grid.data, _item => {
          _item.remark = '';
        })
      } else if (col.name === 'remark') {
        this.$_.forEach(this.grid.data, _item => {
          this.$set(_item, String('day' + this.today), null)
        })
      }
    },
    datechange() {
      this.columnKey = 'day' + this.today; 
      this.$_.forEach(this.grid.data, _item => {
        _item.colkey = this.columnKey;
      })
      this.setColumns();
    },
    setColumns() {
      let _columns = [
        {
          name: 'checkItemPartName',
          field: 'checkItemPartName',
          label: '부품명',
          align: 'center',
          style: 'width:100px',
          sortable: false,
          fix: true,
        },
        {
          name: 'checkItemName',
          field: 'checkItemName',
          label: '점검항목',
          align: 'center',
          style: 'width:120px',
          sortable: false,
          fix: true,
        },
      ]

      let days = [];
      let year = this.data.checkDate.substring(0, 4);
      let month = this.data.checkDate.substring(5);

      let date = new Date(Number(year), Number(month), 0);

      for (let i=1; i<=date.getDate(); i++) {
        this.dateItems.push({
          dayName: i + '일',
          dayCode: String(i),
        })
      }

      if (this.data.checkDate) { 
        days.push(
          {
            name: this.columnKey,
            field: this.columnKey,
            label: '선택',
            currentDay: String(this.today) === String(this.today) && String(month) === String(this.currentMonth) 
                      ? true : false, 
            align: 'center',
            size: 'xs',
            setHeader: true,
            style: 'width:80px',
            comboItems: this.checkboxItems,
            type: 'custom',
            headType: 'select',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '수치입력',
            currentDay: String(this.today) === String(this.today) && String(month) === String(this.currentMonth) 
                      ? true : false, 
            align: 'left',
            setHeader: true,
            style: 'width:90px',
            type: 'custom',
            headType: 'text',
            sortable: false,
          },
        )
        this.grid.columns = this.$_.concat(_columns, 
          {
            name: 'today',
            field: 'today',
            label: this.today + '일',
            child: days
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '',
            align: 'center',
            type: 'custom',
            style: 'width:40px',
            sortable: false
          }
        )
      }
      if (this.data.checkDate && this.data.lineId) {
        this.data.dailyName = year + '년도  ' + month + '월  ' + this.popupParam.lineCd
      }
      this.$_.forEach(this.grid.data, _item => {
        for (let i=1; i<this.today; i++) {
          if (_item['day'+i] && _item['day'+i] !== null) {
            _item.recentDay = i  + '일 [' + _item['day'+i+'Name']+ ']'
          }
        }
      })
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      this.$emit('imprChange')
    },
  }
};
</script>
